export var Language;
(function (Language) {
    Language["German"] = "de";
    Language["English"] = "en";
    Language["Swedish"] = "sv";
})(Language || (Language = {}));
export var DataType;
(function (DataType) {
    DataType["Note"] = "note";
    DataType["Tour"] = "tour";
    DataType["Office"] = "office";
    DataType["User"] = "users";
    DataType["Vehicle"] = "vehicle";
    DataType["Comment"] = "comment";
    DataType["File"] = "file";
    DataType["Storage"] = "storage";
    DataType["Marker"] = "marker";
    DataType["Team"] = "team";
    DataType["Link"] = "link";
    DataType["TourUser"] = "tour-users";
})(DataType || (DataType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
    UserRole["Default"] = "default";
    UserRole["Sales"] = "sales";
    UserRole["Temporary"] = "temp";
})(UserRole || (UserRole = {}));
export var VehicleStatus;
(function (VehicleStatus) {
    VehicleStatus["Operational"] = "operational";
    VehicleStatus["Not_operational"] = "not_operational";
    VehicleStatus["Terminated"] = "terminated";
})(VehicleStatus || (VehicleStatus = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "active";
    UserStatus["Invited"] = "invited";
    UserStatus["Suspended"] = "suspended";
    UserStatus["Deleted"] = "deleted";
})(UserStatus || (UserStatus = {}));
export var VehicleProps;
(function (VehicleProps) {
    VehicleProps["Name"] = "name";
    VehicleProps["Type"] = "type";
    VehicleProps["Volume"] = "volume";
    VehicleProps["Office_Id"] = "office_id";
    VehicleProps["License"] = "license";
    VehicleProps["Max_Weight"] = "max_weight";
    VehicleProps["Dimensions"] = "dimensions";
    VehicleProps["Vehicle_status"] = "vehicle_Status";
})(VehicleProps || (VehicleProps = {}));
export var UserProps;
(function (UserProps) {
    UserProps["Default_Office"] = "default_office";
    UserProps["Email"] = "email";
    UserProps["Employment"] = "employment_type";
    UserProps["Filter"] = "filter";
    UserProps["FirstName"] = "first_name";
    UserProps["Language"] = "language";
    UserProps["LastName"] = "last_name";
    UserProps["Alias"] = "alias";
    UserProps["License"] = "license";
    UserProps["Password"] = "password";
    UserProps["Phone"] = "phone";
    UserProps["Role"] = "role";
    UserProps["Status"] = "status";
    UserProps["Temp_Date"] = "temp_date";
})(UserProps || (UserProps = {}));
export var TourProps;
(function (TourProps) {
    TourProps["Id"] = "id";
    TourProps["Created"] = "created";
    TourProps["Customer"] = "customer";
    TourProps["Date"] = "date";
    TourProps["Desc"] = "desc";
    TourProps["End_Time"] = "end_time";
    TourProps["From"] = "from";
    TourProps["Invoiced"] = "invoiced";
    TourProps["Label"] = "label";
    TourProps["Lime_Id"] = "lime_id";
    TourProps["Linked"] = "linked";
    TourProps["Office_Id"] = "office_id";
    TourProps["Start_Time"] = "start_time";
    TourProps["Title"] = "title";
    TourProps["To"] = "to";
    TourProps["Volume"] = "volume";
    TourProps["Max_Users"] = "max_users";
    TourProps["Max_Vehicles"] = "max_vehicles";
})(TourProps || (TourProps = {}));
export var TourLabel;
(function (TourLabel) {
    TourLabel["None"] = "none";
    TourLabel["Local"] = "local";
    TourLabel["Europe"] = "europe";
    TourLabel["Domestic"] = "domestic";
    TourLabel["Import"] = "import";
    TourLabel["Export"] = "export";
    TourLabel["BoxesIn"] = "boxes_in";
    TourLabel["BoxesOut"] = "boxes_out";
    TourLabel["AddToStore"] = "add_store";
    TourLabel["TakeFromStore"] = "remove_store";
    TourLabel["Assist"] = "assist";
    TourLabel["Internal"] = "internal";
    TourLabel["Cleaning"] = "cleaning";
    TourLabel["Extradition"] = "extradition";
    TourLabel["Inspection"] = "inspection";
    TourLabel["Delivery"] = "delivery";
    TourLabel["Art"] = "art";
})(TourLabel || (TourLabel = {}));
export const DeprecatedTourLabels = [TourLabel.Art, TourLabel.Inspection];
export const tourLabelColors = {
    local: "#6F9CEB",
    europe: "#053162",
    import: "#9B287B",
    export: "#FFB2E6",
    add_store: "#F75C03",
    remove_store: "#E3B505",
    assist: "#107E7D",
    internal: "#5B8C5A",
    cleaning: "#E6FAFC",
    extradition: "#E6FAFC",
    boxes_in: "#E6FAFC",
    boxes_out: "#E6FAFC",
    inspection: "#E6FAFC",
    delivery: "#E6FAFC",
    art: "#E6FAFC",
};
export var NoteProps;
(function (NoteProps) {
    NoteProps["Id"] = "id";
    NoteProps["Created"] = "created_at";
    NoteProps["Desc"] = "desc";
    NoteProps["From"] = "from";
    NoteProps["Office_Id"] = "office_id";
    NoteProps["Title"] = "title";
    NoteProps["To"] = "to";
    NoteProps["Type"] = "type";
})(NoteProps || (NoteProps = {}));
export var NoteType;
(function (NoteType) {
    NoteType["Sick"] = "sick";
    NoteType["Vacation"] = "vacation";
    NoteType["Meeting"] = "meeting";
    NoteType["Inspection"] = "inspection";
    NoteType["Europe"] = "europe";
    NoteType["Other"] = "other";
})(NoteType || (NoteType = {}));
export var Filter;
(function (Filter) {
    Filter["Weekend"] = "weekend";
})(Filter || (Filter = {}));
export var FilterGroup;
(function (FilterGroup) {
    FilterGroup["Other"] = "other_filter";
    FilterGroup["Office"] = "office_filter";
    FilterGroup["Label"] = "label_filter";
})(FilterGroup || (FilterGroup = {}));
export const customer_obj_props = [
    "name",
    "phone_work",
    "phone_private",
    "phone_mobile",
    "email",
    "address1",
    "address2",
    "address3",
    "zipcode",
    "city",
    "portkod",
    "country",
];
export var VehicleTypes;
(function (VehicleTypes) {
    VehicleTypes["CAR"] = "car";
    VehicleTypes["LIGHT_TRUCK"] = "light_truck";
    VehicleTypes["TRUCK"] = "truck";
})(VehicleTypes || (VehicleTypes = {}));
export var VehicleLicense;
(function (VehicleLicense) {
    VehicleLicense["B"] = "B";
    VehicleLicense["C"] = "C";
    VehicleLicense["CE"] = "CE";
    VehicleLicense["None"] = "None";
})(VehicleLicense || (VehicleLicense = {}));
export var EmploymentType;
(function (EmploymentType) {
    EmploymentType["Permanent"] = "permanent";
    EmploymentType["Temporary"] = "temporary";
    EmploymentType["SummerWorker"] = "summer_worker";
    EmploymentType["None"] = "undefined_employment";
})(EmploymentType || (EmploymentType = {}));
export var ResourceStatus;
(function (ResourceStatus) {
    ResourceStatus["Free"] = "free";
    ResourceStatus["Conflict"] = "conflict";
    ResourceStatus["Assigned"] = "assigned";
    ResourceStatus["Note"] = "note";
})(ResourceStatus || (ResourceStatus = {}));
