import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Box, Text, Tip, List } from "grommet";
import { useDrag } from "react-dnd";
import { DataType, ResourceStatus, UserRole } from "../../types";
import { Note01Icon, StopWatchIcon, TruckIcon } from "hugeicons-react";
export const WorkerListEntry = inject('dataStore', 'userStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore, userStore, user } = props;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DataType.User,
        item: { item: user, type: DataType.User },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const renderNoteTip = () => (_jsx(List, { primaryKey: "title", data: user.getNotes }));
    const status = user.getSchedulerStatus;
    const bg_color = user.hasNote ?
        "post-it" :
        (status === ResourceStatus.Conflict ?
            "status-critical-light" :
            (status === ResourceStatus.Assigned ?
                "light-2" :
                undefined));
    const textColor = status === ResourceStatus.Assigned ? "#999999" : undefined;
    return (_jsx(Box, Object.assign({ ref: drag, className: "tourList-entry", flex: false, onClick: () => {
            if (user.role === UserRole.Temporary)
                userStore === null || userStore === void 0 ? void 0 : userStore.setActiveUser(user);
        }, background: bg_color, pad: {
            vertical: "xsmall",
            horizontal: "xsmall"
        }, style: {
            cursor: "grab"
        } }, { children: _jsxs(Box, Object.assign({ className: "tourList-entry-titleLine", direction: "row", justify: "between", gap: "xsmall", flex: false, align: "center" }, { children: [_jsxs(Text, Object.assign({ color: textColor, truncate: true }, { children: [user.displayName, " ", (user.role === UserRole.Temporary) && _jsx(StopWatchIcon, { color: textColor, size: 16 })] })), _jsxs(Box, Object.assign({ direction: "row", gap: "xsmall", align: "center" }, { children: [user.hasNote &&
                            _jsx(Tip, Object.assign({ content: renderNoteTip() }, { children: _jsx(Box, Object.assign({ round: "4px", justify: "center", align: "center", className: "scheduler-resource-list-button" }, { children: _jsx(Note01Icon, { color: textColor, size: 16 }) })) })), _jsx(Text, Object.assign({ color: textColor, size: "small", margin: { right: "small" }, style: { fontStyle: "italic" } }, { children: user.employment_type ? t(`${user.employment_type}_short`) : "-" })), _jsx(Text, Object.assign({ color: textColor, size: "small", margin: { right: "small" } }, { children: user.license || "-" })), _jsx(TruckIcon, { color: textColor, size: 16 })] }))] })) })));
}));
